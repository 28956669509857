import { FETCH_POPULAR_CATEGORIES } from "../actions/types";

export default function (state = [], action) {
    switch (action.type) {
        case FETCH_POPULAR_CATEGORIES:
            return action.payload || false;
        default:
            return state;
    }

}