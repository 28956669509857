import React from "react";
import { MDBContainer, MDBRow, MDBCol } from "mdbreact";
import FooterLogo from "../../../assets/images/footer-logo.png";
import Logo from "../../../assets/images/nextbazaar-logo.png";
import styles from "./FooterCopyrightBlock.module.css";

const FooterCopyrightBlock = (props) => (
  <div className="copy-right-section">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-sm-6">
          <div className="footer-logo">
            <img src={Logo} alt="NextBazaar icon" />
          </div>
        </div>
        <div className="col-sm-6">
          <div className="copyright-text">
            <p>Copyright 2019 &copy; All Rights Reserved.</p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default FooterCopyrightBlock;
