import axios from "axios";
import jwtDecode from "jwt-decode";
import {
  AUTH_STATE,
  FETCH_CITIES,
  FETCH_CUSTOM_FIELDS,
  STORE_COUNTRY,
  FETCH_CATEGORIES,
  FETCH_POPULAR_CATEGORIES,
  FETCH_ADS,
  FETCH_AUTH_USER,
  FETCH_FAV_ADS,
  FETCH_COUNTRIES,
  COUNT_MY_ADS,
  Hold_Search_Query,
  HOLD_CHAT_QUERY,
  FETCH_MY_ADS,
  FETCH_ADS_Pagination,
  EDIT_MY_AD,
  COUNT_VISITOR_ADS,
  GET_SAVED_SEARCH,
  GET_CHAT_LIST,
  ADS_SETTINGS,
} from "./types";
import helpers from "../utils/helpers";
import _ from "lodash";
import config from "../utils/development.json";
axios.defaults.baseURL = `${config.baseUrl}`;
// process.env.REACT_APP_API_URL;
const apiBaseUrl = `/api`;

export const checkTokenExpiration = () => {
  let flag = true;
  if (jwtDecode(localStorage.getItem("token")).exp < Date.now() / 1000) {
    localStorage.removeItem("token");
    flag = false;
    return flag;
  }
  return flag;
};

export const fakeAuthCentralState = {
  isAuthenticated:
    localStorage.getItem("token") && checkTokenExpiration() ? true : false,
};

export const authenticate = () => (dispatch) => {
  dispatch({
    type: AUTH_STATE,
    payload: { auth: fakeAuthCentralState.isAuthenticated },
  });
};

export const signout = () => (dispatch) => {
  dispatch({
    type: AUTH_STATE,
    payload: { auth: fakeAuthCentralState.isAuthenticated },
  });
  dispatch({
    type: FETCH_AUTH_USER,
    payload: {},
  });
};

export const forgetPassword = (email) => {
  console.log("email", email);
  return axios
    .get(apiBaseUrl + "/forget-password?email=" + `${email}`)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const getReportTypes = () => {
  return axios
    .get(apiBaseUrl + "/report-type")
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const postReport = (data) => {
  return axios.post(apiBaseUrl + "/report-type", data);
};
export const resetPassword = (data) => {
  console.log("data", data);
  return axios
    .post(apiBaseUrl + "/reset", data)
    .then((res) => {
      console.log("status", res);

      return res;
    })
    .catch((err) => {
      console.log("error", err);
      return err;
    });
};

export const signup = (data) => {
  console.log("data", data);
  return axios
    .post(apiBaseUrl + "/register", data)
    .then((res) => {
      console.log("res", res.data);
      return res.data;
    })
    .catch((err) => {
      console.log("err", err.response.data);

      return err.response.data;
    });
};

export const login = (data) => {
  console.log("data", data);
  return axios
    .post(apiBaseUrl + "/login", data)
    .then((res) => res)
    .catch((err) => err.response.data);
};

export const getAuthUser = (token) => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/user", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      localStorage.setItem('_id', res.data.user._id);
      return dispatch({ type: FETCH_AUTH_USER, payload: res.data.user })
    })
    .catch((err) => err);
};

export const getCategoriesWithSubCategories = () => {
  return axios.get(apiBaseUrl + "/categories/list").then((res) => {
    return res;
  });
};

export const adsSetting = () => (dispatch) => {
  return axios.get(apiBaseUrl + "/settings/ad-single-page").then((res) => {
    // return res;
    dispatch({ type: ADS_SETTINGS, payload: res.data.data[0] });
  });
};

export const getPopularCategories = (country = "") => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/popular/categories?country_code=" + country)
    .then((res) => {
      dispatch({ type: FETCH_POPULAR_CATEGORIES, payload: res.data });
    });
};

export const getCategories = (country = "") => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/categories?country_code=" + country)
    .then((res) => {
      dispatch({ type: FETCH_CATEGORIES, payload: res.data.data });
    });
};

export const getSubCategoriesByCategoryId = (categoryId) => {
  return axios.get(apiBaseUrl + "/subcategories/" + categoryId).then((res) => {
    // console.log('sub categories', res);
    return res;
  });
};

export const getAds = () => (dispatch) => {
  return axios.get(apiBaseUrl + "/ads").then((res) => {
    // console.log("ads", res);
    dispatch({ type: FETCH_ADS, payload: res.data.data });
  });
};

export const getMyAds = (token, page = 1, search = "", country_code = "") => (
  dispatch
) => {
  return axios
    .get(
      apiBaseUrl +
        "/account/ads?page=" +
        page +
        "&search=" +
        search +
        "&country_code=" +
        country_code,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch({ type: FETCH_MY_ADS, payload: res.data });
    });
};

export const getMyFavAds = (
  token,
  page = 1,
  search = "",
  country_code = ""
) => (dispatch) => {
  return axios
    .get(
      apiBaseUrl +
        "/account/favourites?page=" +
        page +
        "&search=" +
        search +
        "&country_code=" +
        country_code,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch({ type: FETCH_MY_ADS, payload: res.data });
    });
};

export const getPendingAds = (
  token,
  page = 1,
  search = "",
  country_code = ""
) => (dispatch) => {
  return axios
    .get(
      apiBaseUrl +
        "/account/pendingAds?page=" +
        page +
        "&search=" +
        search +
        "&country_code=" +
        country_code,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch({ type: FETCH_MY_ADS, payload: res.data });
    });
};

export const getArchivedAds = (
  token,
  page = 1,
  search = "",
  country_code = ""
) => (dispatch) => {
  return axios
    .get(
      apiBaseUrl +
        "/account/archivedAds?page=" +
        page +
        "&search=" +
        search +
        "&country_code=" +
        country_code,
      {
        headers: { Authorization: `Bearer ${token}` },
      }
    )
    .then((res) => {
      dispatch({ type: FETCH_MY_ADS, payload: res.data });
    });
};

export const countAds = (token, country_code = "") => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/account/count/ads?country_code=" + country_code, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: COUNT_MY_ADS, payload: res.data.data });
    });
};

export const getAdsPagination = (url) => (dispatch) => {
  return axios.get(apiBaseUrl + url).then((res) => {
    dispatch({ type: FETCH_ADS_Pagination, payload: res.data });
  });
};
export const getAllAds = (setAds) => {
  return axios.get(apiBaseUrl + "/ads").then((res) => {
    setAds(res.data.data);
    // dispatch({ type: FETCH_ADS, payload: res.data.data });
  });
};

export const postAd = (token, data) => {
  return axios
    .post(apiBaseUrl + "/ads", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((newAd) => {
      return newAd;
    })
    .catch((err) => {
      return err;
    });
};

export const fetchCountries = () => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/countries")
    .then((res) => dispatch({ type: FETCH_COUNTRIES, payload: res.data.data }));
};

export const storeCountry = (data) => (dispatch) =>
  dispatch({ type: STORE_COUNTRY, payload: data });

export const fetchCitiesByCountryCode = (countryCode) => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/city/" + countryCode)
    .then((res) => dispatch({ type: FETCH_CITIES, payload: res.data.data }));
};

export const filterCitiesByCountryCode = (countryCode) => {
  return axios
    .get(apiBaseUrl + "/city/" + countryCode)
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getCustomFieldsByCategoryId = (categoryId) => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/category_custom_fields/" + categoryId)
    .then((res) => dispatch({ type: FETCH_CUSTOM_FIELDS, payload: res.data }));
};
export const searchAds = (data) => (dispatch) => {
  return axios.post(apiBaseUrl + "/ads/search", data).then((res) => {
    dispatch({ type: FETCH_ADS_Pagination, payload: res.data });
  });
};
export const similarAds = (id, country_code = "") => {
  return axios
    .get(apiBaseUrl + "/similar-ads/" + id + "?country_code=" + country_code)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const filterAds = (data) => (dispatch) => {
  return axios.post(apiBaseUrl + "/ads/filter", data).then((res) => {
    dispatch({ type: FETCH_ADS_Pagination, payload: res.data });
  });
};
export const visitorAds = (id, token) => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/number-of-visitor/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: COUNT_VISITOR_ADS, payload: res.data });
    });
};

export const searchAdsLoadMore = (url, data) => (dispatch) => {
  return axios.post(apiBaseUrl + url, data).then((res) => {
    console.log("ads", res);
    dispatch({ type: FETCH_ADS_Pagination, payload: res.data });
  });
};

export const holdQuery = (data) => (dispatch) => {
  dispatch({ type: Hold_Search_Query, payload: data });
};

export const holdChatQuery = (data) => (dispatch) => {
  dispatch({ type: HOLD_CHAT_QUERY, payload: data });
};

export const editAdAction = (data) => (dispatch) => {
  console.log("Edit Called");
  dispatch({ type: EDIT_MY_AD, payload: data });
};
// export const searchAds = (data) => {
//   return axios
//     .post(apiBaseUrl + "/ads/search", data)
//     .then((res) => {
//       dispatch({ type: FETCH_ADS, payload: res.data.data });

//       // return res;
//       // dispatch({ type: FETCH_ADS, payload: res.data.data });
//     })
//     .catch((err) => {
//       console.log(err);
//       return err;
//     });
// };

export const searchAds2 = (data) => (dispatch) => {
  return axios
    .post(apiBaseUrl + "/ads/search", data)
    .then((res) => {
      console.log(res);
      return dispatch({ type: FETCH_ADS, payload: res.data.data });
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
export const getChatList = (token) => (dispatch) => {
  return axios
    .get(apiBaseUrl + "/get-chat-list", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return dispatch({ type: GET_CHAT_LIST, payload: res.data });
    })
    .catch((err) => {
      return err;
    });
};

export const getChatDetail = (token, id, url = "") => {
  if (!_.isEmpty(url)) {
    return axios.get(url, {
      headers: { Authorization: `Bearer ${token}` },
    });
  } else {
    return axios.get(apiBaseUrl + "/get-chat/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    });
  }
};

export const postMessage = (token, data) => {
  return axios.post(apiBaseUrl + "/post-message", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const addToFavourite = (token, data) => {
  return axios
    .post(apiBaseUrl + "/account/ad_to_favourite", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => err);
};

export const getFavouriteAds = (token, data) => (dispatch) => {
  return axios
    .post(apiBaseUrl + "/account/favourites", data, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: FETCH_FAV_ADS, payload: res.data });
    })
    .catch((err) => err);
};

export const getSaveSearches = (token, page = "") => (dispatch) => {
  return axios
    .get(apiBaseUrl + page, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      dispatch({ type: GET_SAVED_SEARCH, payload: res.data });
    })
    .catch((err) => err);
};
export const deleteSavedSearch = (token, id) => {
  return axios
    .get(apiBaseUrl + "/account/delete/savedSearch/" + id, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    });
};

export const viewAd = (token, adId, user_id) => {
  return axios
    .get(apiBaseUrl + "/ads/" + adId + "?userId=" + user_id)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};
export const editAd = (token, adId) => {
  return axios
    .get(apiBaseUrl + "/account/ads/edit/" + adId, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      return res;
    })
    .catch((err) => {
      return err;
    });
};

export const updateAd = (token, adId, ad) => {
  return axios
    .put(apiBaseUrl + "/ads/" + adId, ad, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const deleteSingleAd = (token, adId) => {
  return axios
    .get(apiBaseUrl + "/account/" + adId + "/delete", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const deleteMultipleAds = (token, remove_ads) => {
  return axios
    .post(
      apiBaseUrl + "/account/delete",
      { remove_ads },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      helpers.toastify("error", err.response.data.message);

      // return err;
    });
};

export const deleteMultiplePendingAds = (token, remove_ads) => {
  return axios
    .post(
      apiBaseUrl + "/account/delete-pending-ads",
      { remove_ads },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      helpers.toastify("error", err.response.data.message);

      // return err;
    });
};

export const deleteMultipleFavAds = (token, remove_ads) => {
  return axios
    .post(
      apiBaseUrl + "/account/favourites/delete",
      { remove_ads },
      { headers: { Authorization: `Bearer ${token}` } }
    )
    .then((res) => {
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const getGenderOptions = (token) => {
  return axios
    .get(apiBaseUrl + "/user/gender", {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const updateUser = (token, user) => {
  return axios
    .post(apiBaseUrl + "/user/update", user, {
      headers: { Authorization: `Bearer ${token}` },
    })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};
export const postSavedSearch = (token, obj) => {
  return axios.post(apiBaseUrl + "/saved-search", obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const updateProfileAvatar = (token, profile_pic) => {
  return axios
    .post(apiBaseUrl + "/user/avatar", { token, profile_pic })
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const recoverPassword = (obj) => {
  return axios
    .post(apiBaseUrl + "/recover/", obj)
    .then((res) => {
      console.log(res);
      return res;
    })
    .catch((err) => {
      console.log(err);
      return err;
    });
};

export const resetPasswordProfile = (obj, token) => {
  return axios.post(apiBaseUrl + "/user/reset-password", obj, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
