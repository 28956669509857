import { FETCH_ADS,FETCH_ADS_Pagination } from '../actions/types';

export default function (state = [], action) {
    switch (action.type) {
        case FETCH_ADS:
            return action.payload || false
        case FETCH_ADS_Pagination:
            return action.payload || false
        default:
            // return {};
            return state;
    }
}