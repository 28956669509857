export const AUTH_STATE = 'auth_state';
export const FETCH_CATEGORIES = 'fetch_categories';
export const FETCH_POPULAR_CATEGORIES = 'fetch_popular_categories';
export const FETCH_COUNTRIES = 'fetch_countries';
export const FETCH_ADS = 'fetch_ads';
export const FETCH_MY_ADS = 'fetch_my_ads';
export const COUNT_MY_ADS = 'count_my_ads';
export const ADS_SETTINGS = 'ads_settings';
export const EDIT_MY_AD = 'edit_my_ad';
export const GET_SAVED_SEARCH = 'get_saved_search';
export const COUNT_VISITOR_ADS = 'count_visitor_ads';
export const FETCH_REPORT_TYPES = 'fetch_report_types';
export const FETCH_ADS_Pagination = 'fetch_ads_pagination';
export const FETCH_CUSTOM_FIELDS = 'fetch_custom_fields';
export const STORE_COUNTRY = 'store_country';
export const FETCH_CITIES = 'fetch_cities';
export const FETCH_AUTH_USER = 'fetch_auth_user';
export const FETCH_FAV_ADS = 'fetch_fav_ads';
export const Hold_Search_Query = 'fetch_search_query';
export const HOLD_CHAT_QUERY = 'hold_chat_query';
export const GET_CHAT_LIST = 'get_chat_list';
