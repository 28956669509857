import React, { Component } from "react";
import Aux from "../../hoc/Auxx";
import styles from "./Header.module.css";
import Logo from "../Logo/Logo";
import { MDBContainer } from "mdbreact";
import ReactFlagsSelect from "react-flags-select";
import "../../assets/css/react-flags-select.css";
import TopLinks from "./TopLinks/TopLinks";
import { connect } from "react-redux";
import {
  fakeAuthCentralState,
  authenticate,
  fetchCountries,
  fetchCitiesByCountryCode,
  storeCountry,
  getCategories,
  getAuthUser,
} from "../../actions";
import _ from "lodash";

class Header extends Component {
  state = {
    language: "en",
    countries: [],
    prevCountry : ''
  };

  handleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onFlagSelect = (selection) => {
    let currency;
    this.state.countries.map((country,index)=>{
      if(country.id === selection){
        currency = country.currency;
      }
    })
    this.props.fetchCitiesByCountryCode(selection);
    this.props.storeCountry({ country_code: selection,currency:currency });
     
  };

  onChangeLanguage = (event) => {
    let selectedLanguage = event.currentTarget.textContent.toLowerCase();
    if (selectedLanguage !== this.state.language) {
      this.setState({ language: selectedLanguage });
    }
  };

  componentDidMount() {
    this.onFlagSelect('PK')
    this.props.fetchCountries();
    this.props.getCategories();
    this.props.authenticate();
    if (fakeAuthCentralState.isAuthenticated) {
      this.props.getAuthUser(localStorage.getItem("token"));
    }
    
  }

  UNSAFE_componentWillReceiveProps(props) {
    if (!_.isEmpty(props.countries) && _.isEmpty(this.state.countries)) {
      this.setState({
        countries: props.countries,
      });
    }
    // countries selection is disabled in first release
    // if(!_.isEmpty(props.country)){
    //   this.refs.userFlag.updateSelected(props.country.country_code)
    // }
  }

  render() {
    const countries = this.state.countries.map((country) => country.id);

    const flagsComponent = (
      <ReactFlagsSelect
      defaultCountry="PK"
        placeholder="Select Region"
        countries={countries}
        ref="userFlag"
        onSelect={this.onFlagSelect}
      />
    );

    const logoComponent = <Logo logoType={"header"} logoLink={"/"} />;

    return (
      <Aux>
        <TopLinks
            // countries selection is disabled in first release
            // flagDropDown={flagsComponent}
            styles={styles}
            logo={logoComponent}
            language={this.state.language}
            languageChange={this.onChangeLanguage}
            adFormTrigger={this.props.adFormTrigger}
            authState={this.props.authState}
        />
      </Aux>
    );
  }
}

function mapStateToProps({ countries, authState,country }) {
  return {
    countries,
    country,
    authState,
  };
}

export default connect(
  mapStateToProps,
  {

    authenticate,
    fetchCountries,
    fetchCitiesByCountryCode,
    storeCountry,
    getCategories,
    getAuthUser,
  },
)(Header);
