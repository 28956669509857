import { Hold_Search_Query } from '../actions/types';

export const querySearch =  (state = [], action) =>  {
    switch (action.type) {
        case Hold_Search_Query:
            console.log(action.payload);
            return action.payload || false
        default:
            return state;
    }
}