import React, { Component, Suspense } from "react";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "bootstrap-css-only/css/bootstrap.min.css";

import "mdbreact/dist/css/mdb.css";
import "./App.css";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { fakeAuthCentralState } from "./actions";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import "antd/dist/antd.css";
import LazyLoader from "./components/lazyLoader/index";
import Header from "./components/Header/Header";
import Footer from "./components/Footer/Footer";
// const Header = React.lazy(() => import('./components/Header/Header'));
// const Footer = React.lazy(() => import('./components/Footer/Footer'));
const SigninForm = React.lazy(() =>
  import("./containers/Registration/SigninForm/SigninForm")
);
const SignupForm = React.lazy(() =>
  import("./containers/Registration/SignupForm/SignupForm")
);
const AdListing = React.lazy(() =>
  import("./components/ads/listing/AdListing")
);
const AdPosting = React.lazy(() =>
  import("./components/ads/posting/AdPosting")
);
const AdSearching = React.lazy(() =>
  import("./components/ads/searching/AdSearching")
);
const AdComponent = React.lazy(() =>
  import("./components/ads/viewAd/AdComponent")
);
const Confirm = React.lazy(() => import("./components/pages/Confirm"));
const Profile = React.lazy(() => import("./components/profile/Profile"));
const AntiScam = React.lazy(() => import("./components/pages/antiScam"));
const FAQ = React.lazy(() => import("./components/pages/FAQ"));
const Terms = React.lazy(() => import("./components/pages/Terms"));

// import Header from "./components/Header/Header";
// import Footer from "./components/Footer/Footer";
// import SigninForm from "./containers/Registration/SigninForm/SigninForm";
// import SignupForm from "./containers/Registration/SignupForm/SignupForm";
// import AdListing from "./components/ads/listing/AdListing";
// import AdPosting from "./components/ads/posting/AdPosting";
// import AdSearching from "./components/ads/searching/AdSearching";
// import AdComponent from "./components/ads/viewAd/AdComponent";
// import Confirm from "./components/pages/Confirm";
// import Profile from "./components/profile/Profile";
// import AntiScam from "./components/pages/antiScam";
// import FAQ from "./components/pages/FAQ";
// import Terms from "./components/pages/Terms";
const Categories = React.lazy(() => import("./components/pages/Categories"));
const ContactUs = React.lazy(() => import("./components/pages/ContactUs"));
const Privacy = React.lazy(() => import("./components/pages/Privacy"));
const ForgetPassword = React.lazy(() =>
  import("./containers/Registration/SigninForm/ForgetPassword")
);
const ResetPassword = React.lazy(() =>
  import("./containers/Registration/SigninForm/ResetPassword")
);
const AdsListing = React.lazy(() =>
  import("./components/ads/listing/AdsListing")
);
const EditPost = React.lazy(() => import("./components/ads/posting/EditPost"));

// import ContactUs from "./components/pages/ContactUs";
// import Privacy from "./components/pages/Privacy";
// import ForgetPassword from "./containers/Registration/SigninForm/ForgetPassword";
// import ResetPassword from "./containers/Registration/SigninForm/ResetPassword";
// import AdsListing from "./components/ads/listing/AdsListing";

// import EditPost from "./components/ads/posting/EditPost";
const ProtectedRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      fakeAuthCentralState.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

const ProtectedRouteBeforeLogin = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      fakeAuthCentralState.isAuthenticated !== true ? (
        <Component {...props} />
      ) : (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      )
    }
  />
);

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visibleSearchBar: true,
      isAdFormShown: true,
    };
    toast.configure();
  }

  adFormShowHandler = () => {
    this.setState({ isAdFormShown: !this.state.isAdFormShown });
  };

  render() {
    return (
      <div className="next-container">
        <BrowserRouter>
          {/* <LazyLoader /> */}

          <div>
            <Header />
            <Suspense fallback={<LazyLoader />}>
              <Switch>
                <Route
                  path="/signup"
                  render={(props) =>
                    fakeAuthCentralState.isAuthenticated ? (
                      <Redirect to="/profile" />
                    ) : (
                      <SignupForm {...props} />
                    )
                  }
                />
                <Route path="/antiScam" component={AntiScam} />
                <Route path="/privacy" component={Privacy} />
                <Route path="/faq" component={FAQ} />
                <Route path="/terms" component={Terms} />
                <Route path="/contactUs" component={ContactUs} />
                <Route path="/categories" component={Categories} />
                <ProtectedRouteBeforeLogin
                  path="/resetPassword/:status?/:token?"
                  component={ResetPassword}
                />

                <ProtectedRouteBeforeLogin
                  path="/forgetPassword"
                  component={ForgetPassword}
                />
                <Route path="/adsListing" component={AdsListing} />

                <ProtectedRouteBeforeLogin
                  path="/login"
                  component={SigninForm}
                />
                <Route
                  exact
                  path="/ads/ad/:title?/:id"
                  component={AdComponent}
                />
                <Route path="/ads" component={AdListing} />
                <ProtectedRouteBeforeLogin
                  exact
                  path="/confirm"
                  component={Confirm}
                />
                <ProtectedRoute path="/adPost" component={AdPosting} />
                <ProtectedRouteBeforeLogin
                  path="/guest/adPost"
                  component={AdPosting}
                />
                <ProtectedRoute path="/editPost/:id" component={EditPost} />
                <ProtectedRoute path="/profile" component={Profile} />
                <Route path="/search" component={AdSearching} />
                <Redirect from="/" to="/ads" />
              </Switch>
            </Suspense>
            <Footer />
          </div>
        </BrowserRouter>
      </div>
    );
  }
}

export default App;
