import { FETCH_ADS } from "../actions/types";

const AdsReducer = (state = [], action) => {
  switch (action.type) {
    case FETCH_ADS:
      return action.payload || false;
    default:
      return state;
  }
};
const AdsRootReducer = {
  AdsReducer,
};
export default AdsRootReducer;
