import { HOLD_CHAT_QUERY,GET_CHAT_LIST } from '../actions/types';

export const chatQuery = (state = [],action) => {
    switch (action.type) {
        case HOLD_CHAT_QUERY:
            return action.payload || false
        default:
            return state;
    }
}
export const getChatList = (state = [],action) => {
    switch (action.type) {
        case GET_CHAT_LIST:
            return action.payload || false
        default:
            return state;
    }
}