import { combineReducers } from "redux";

import authStateReducer from "./authStateReducer";
import fetchCategoriesReducer from "./fetchCategoriesReducer";
import fetchPopularCategories from "./fetchPopularCategories";
import fetchCountriesReducer from "./fetchCountriesReducer";
import fetchAdsReducer from "./fetchAdsReducer";
import fetchCitiesReducer from "./fetchCitiesReducer";
import fetchCustomFieldsReducer from "./fetchCustomFieldsReducer";
import storeCountryReducer from "./storeCountryReducer";
import fetchAuthUserReducer from "./fetchAuthUserReducer";
import fetchFavAdsReducer from "./fetchFavAdsReducer";
import {querySearch} from "./QuerySearchReducer";
import {chatQuery,getChatList} from './chatReducer';
import AdsRootReducer from "./AdsReducer";
import {MyAdsReducer,CountAdsReducer,EditAdReducer} from "./FetchMyAdsReducer";
import VisitorsAdsReducer from "./VisitorsAdsReducer";
import {adsSingleSettings} from './SettingReducer';
import SaveSeachesReducer from "./SaveSeachesReducer";
export default combineReducers({
  authState: authStateReducer,
  categories: fetchCategoriesReducer,
  countries: fetchCountriesReducer,
  ads: fetchAdsReducer,
  allAds: AdsRootReducer.AdsReducer,
  cities: fetchCitiesReducer,
  customFields: fetchCustomFieldsReducer,
  country: storeCountryReducer,
  authUser: fetchAuthUserReducer,
  favAds: fetchFavAdsReducer,
  popular_categories : fetchPopularCategories,
  query_search : querySearch,
  chat_query : chatQuery,
  chat_list : getChatList,
  my_ads : MyAdsReducer,
  countAds : CountAdsReducer, 
  editAd : EditAdReducer,
  visitors : VisitorsAdsReducer,
  adsSingleSettings : adsSingleSettings,
  save_searches : SaveSeachesReducer
});
