import { toast } from "react-toastify";

const helpers = {
  toastify: (status, msg) => {
    toast[status](msg, {
      position: toast.POSITION.TOP_RIGHT,
      autoClose: 3000,
    });
  },

  createMarkup: (html) => {
    return { __html: html };
  },
  nFormatter: (num, digits) => {
    var si = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "G" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" },
    ];
    var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var i;
    for (i = si.length - 1; i > 0; i--) {
      if (num >= si[i].value) {
        break;
      }
    }
    return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
  },
  monthFormat: (d) => {
    var month = [];
    month[0] = "Jan";
    month[1] = "Feb";
    month[2] = "Mar";
    month[3] = "Apr";
    month[4] = "May";
    month[5] = "Jun";
    month[6] = "Jul";
    month[7] = "Aug";
    month[8] = "Sep";
    month[9] = "Oct";
    month[10] = "Nov";
    month[11] = "Dec";
    var n = month[new Date(d).getMonth()];
    var de = new Date(d).getDate();

    if (de < 10) {
      de = "0" + de;
    }
    return n + " " + de;
  },
  dateFormatter: (dt) => {
    const time = new Date(dt);
    var monthAndDay = helpers.monthFormat(dt);
    return monthAndDay + ", " + time.getFullYear();
  },
  formatAMPM: (dt) => {
    const date = new Date(dt);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
  },
};

export default helpers;
