import React,{Component} from 'react';
import { MDBContainer, MDBNavbar, MDBNavbarBrand, MDBNavbarNav, MDBNavItem, MDBNavLink, MDBNavbarToggler, MDBCollapse, MDBFormInline,
    MDBDropdown, MDBDropdownToggle, MDBDropdownMenu, MDBDropdownItem } from "mdbreact";
import { BrowserRouter as Router } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { connect } from "react-redux";
import {adsSetting} from '../../../actions/index';
import _ from 'lodash';
import {
    holdQuery,
  } from "../../../actions";

import "./top-link.css";


class TopLinks extends Component{
    constructor(props){
        super(props)
        this.state = {
            prevpage : ''
        }
    }
    queryEmpty = () => {
        let obj = {}
        this.props.holdQuery(obj);
    }
    componentDidMount(){
        this.props.adsSetting()
    }
    state = {
        isOpen: false
    };

    toggleCollapse = () => {
        this.setState({ isOpen: !this.state.isOpen });
    }
    // static getDerivedStateFromProps(nextProps){
    //     nextProps.adsSetting();
    // }
    // componentDidUpdate(prevProps,prevState){
    //     if(_.isEmpty(this.state.prevpage)){
    //         this.setState({
    //             prevpage:window.location.href
    //         })
    //     }else{
    //         if(prevState.prevpage !== this.state.prevpage){
    //             this.props.adsSetting()
    //             this.setState({
    //                 prevProps : window.location.href
    //             })
    //         }
    //     }
    // }
    render(){
        return (
            <MDBNavbar dark expand="md" className="main-navbar">
                <MDBContainer>
                    <MDBNavbarBrand>
                        {this.props.logo}
                    </MDBNavbarBrand>
                    <MDBNavbarToggler onClick={this.toggleCollapse} />
                    <MDBCollapse id="navbarCollapse3" isOpen={this.state.isOpen} navbar>
                        <MDBNavbarNav right>
                            <MDBNavItem className="country-flags">
                                {this.props.flagDropDown}
                            </MDBNavItem>

                            <MDBNavItem className="language-select">
                                <MDBDropdown size="sm" className={this.props.styles.lanDropDown}>
                                    <MDBDropdownToggle>{this.props.language.toUpperCase()}<i class="fas fa-caret-down light-gray-text-color"></i></MDBDropdownToggle> 
                                    <MDBDropdownMenu>
                                        <MDBDropdownItem onClick={this.props.languageChange}>EN</MDBDropdownItem>
                                        <MDBDropdownItem onClick={this.props.languageChange}>FR</MDBDropdownItem>
                                        <MDBDropdownItem onClick={this.props.languageChange}>SP</MDBDropdownItem>
                                    </MDBDropdownMenu>
                                </MDBDropdown>
                            </MDBNavItem>
                            <MDBNavItem className="registration-Links">
                                { !this.props.authState.auth && (
                                    <>
                                        <Link className="orange-text-color" to="/signup">Register</Link>
                                        <span className="medium-gray-text-color">or</span>
                                        <Link className="orange-text-color" to="/login">Sign In</Link>
                                    </>
                                )}
                            </MDBNavItem>
                            <MDBNavItem className="topLink-btn-holder">
                                <Link className="custom-primary-btn" onClick={this.queryEmpty} to="/adsListing">Ads</Link>
                            </MDBNavItem>

                            { this.props.authState.auth && (
                                <MDBNavItem className="topLink-btn-holder">
                                    <Link className="custom-primary-btn" to="/adPost">Post ad</Link>
                                </MDBNavItem>
                            )}

                            { (this.props.authState.auth === false) && !_.isEmpty(this.props.adsSingleSettings)
                            && (this.props.adsSingleSettings.value.guests_can_post_ads === "1") && (
                                <MDBNavItem className="topLink-btn-holder">
                                    <Link className="custom-primary-btn" to="/guest/adPost">Post ad</Link>
                                </MDBNavItem>
                            )}
                            { this.props.authState.auth && (
                                <MDBNavItem className="topLink-btn-holder">
                                    <Link className="custom-primary-btn" to="/profile">Profile</Link>
                                </MDBNavItem>
                            )}
                        </MDBNavbarNav>
                    </MDBCollapse>
                </MDBContainer>
            </MDBNavbar>
        )
    }
}
function mapStateToProps({adsSingleSettings }) {
    return {
        adsSingleSettings
    }
}
export default connect(mapStateToProps,{ holdQuery,adsSetting })(TopLinks); 