import React from 'react';
import siteLogo from '../../assets/images/nextbazaar-logo.png';
import siteFooterLogo from '../../assets/images/nextbazaar-logo2.png';
import styles from './Logo.module.css';
import { Link } from 'react-router-dom/cjs/react-router-dom';

const Logo = (props) => (
    <Link to={props.logoLink} className="logo">
        {props.logoType==='footer' ? (
            <img src={siteFooterLogo} alt="NextBazaar Logo" />
        ) : (
            <img src={siteLogo} alt="NextBazaar Logo" />
        )}
    </Link>
);

export default Logo;