import { FETCH_MY_ADS,COUNT_MY_ADS,EDIT_MY_AD } from '../actions/types';

export const MyAdsReducer = (state = [], action) =>  {
    switch (action.type) {
        case FETCH_MY_ADS:
            return action.payload || false
        default:
            return state;
    }
}


export const CountAdsReducer = (state = [], action) =>  {
    switch (action.type) {
        case COUNT_MY_ADS:
            return action.payload || false
        default:
            return state;
    }
}

export const EditAdReducer = (state = [], action) => {
    switch (action.type) {
      case EDIT_MY_AD:
        return action.payload || false;
      default:
        return state;
    }
  };