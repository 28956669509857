import { COUNT_VISITOR_ADS } from "../actions/types";

export default function (state = {}, action) {

    switch (action.type) {
        case COUNT_VISITOR_ADS:
            return action.payload;
        default: 
            return state;
    }
}