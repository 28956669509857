import React,{Component} from "react";
import Aux from "../../../hoc/Auxx";
import styles from "./FooterLinksBlock.module.css";
import { MDBContainer, MDBRow, MDBCol, MDBIcon } from "mdbreact";
import apple from "../../../assets/images/icon/app-apple.png";
import andriod from "../../../assets/images/icon/app-andriod.png";
import { Link } from "react-router-dom";
import { withRouter,useLocation } from "react-router-dom";
import { holdQuery, getPopularCategories } from "../../../actions";
import { connect } from "react-redux";

class FooterLinksBlock  extends Component {

  categorySelect(category, sub = "") {
    let obj = { ...this.props.query };
    if (typeof sub.id != "undefined") {
      obj = {
        selected_category: [sub, category],
        parent_id: sub.id,
        title: "",
        city_id: "",
      };
    }
    if (category && typeof sub.id == "undefined") {
      obj = {
        selected_category: [category],
        parent_id: category.id,
        title: "",
        city_id: "",
      };
    }
    // this.props.holdQuery(obj);
    // this.props.history.push("/adsListing");
  }
  componentDidMount() {
    if(this.props.popular_categories.length <= 0){
      this.props.getPopularCategories(this.props.country.country_code);
    }
  }
  render() {
    return (
      <>
  <Aux>
    <footer className="footer-wrapper">
      <div className="container">
        <div className="row">
          <div className="col-lg-3 col-md-4 col-6">
            <div className="footer-block">
              <h6>Popular Categories</h6>
              <ul className="footer-links">
              {this.props.popular_categories.map((category, index) => {
                if (index < 4) {
                return (<li>
                  <a onClick={this.categorySelect.bind(this, category)}>{category.title}</a>
                </li>)
                }})}
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div className="footer-block">
              <h6>About us</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/antiScam">Anti-Scam</Link>
                </li>
                <li>
                  <Link to="/terms">Terms</Link>
                </li>
                <li>
                  <Link to="/faq">FAQ</Link>
                </li>
                <li>
                  <Link to="/privacy">Privacy</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div className="footer-block">
              <h6>Contact & Sitemap</h6>
              <ul className="footer-links">
                <li>
                  <Link to="/contactUs">Contact us</Link>
                </li>
                <li>
                  <Link to="/siteMap">Sitemap</Link>
                </li>
                <li>
                  <Link to="/countries">Countries</Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-lg-2 col-md-4 col-6">
            <div className="footer-block">
              <div className={styles.footerBlock}>
                <h6>My Account</h6>
                <ul className="footer-links">
                  <li>
                    <a href="/login">Login In</a>
                  </li>
                  <li>
                    <a href="/signup">Register</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-lg-3 col-md-4 col-6">
            <div className="footer-block">
              <h6>Follow us</h6>
              <ul className="footer-links social-icons">
                <li>
                  <a href="/">
                    <i class="fab fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i class="fab fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a href="/">
                    <i class="fab fa-instagram"></i>
                  </a>
                </li>
              </ul>
              <div className="app-icons">
                {/* NOT DELIVERED IN FIRST RELEASE */}
                {/* <a href="javascript:void(0)"><img src={apple} alt="NextBazaar icon" /></a>
                <a href="javascript:void(0)"><img src={andriod} alt="NextBazaar icon" /></a> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  </Aux>
  </>
  )
  }
}
function mapStateToProps(state) {
  return {
    popular_categories: state.popular_categories,
    query: state.query_search,
    country: state.country,
  };
}

export default withRouter(
  connect(mapStateToProps, { holdQuery, getPopularCategories })(
    FooterLinksBlock
  )
);
// export default FooterLinksBlock;
